(function (canopyCore) {
	"use strict";

	canopyCore.directive("canopyCoreFieldKeywords", function ($filter) {
		return {
			restrict: "A",
			controller: function ($scope, lumaAPI) {
				
				const localise = $filter("canopyLocalise");

				function initKeywordOptions() {
					return lumaAPI.listOptionsForTypeField($scope.field.id)
						.then((response) => {
							$scope.field.options = response.data.map(transformOptionsFn($scope.field));
							if (!$scope.field.values && Object.hasOwn($scope.field, "revert")) {
								$scope.field.options = response.data.map(transformOptionsFn(transformDefaultValue($scope.field)));
							}
						});
				}
				
				function transformOptionsFn(field) {
					return (option) => {
						return {
							...option,
							name: option.value,
							selected: field.values?.findIndex((saved) => saved.name === option.value) >= 0,
							title: option.localisation ? localise(option.localisation) : option.value,
						};
					};
				}

				function transformDefaultValue(field) {
					const defaultValues = field.revert?.split(/\s*,\s*/) || [];
					return {
						...field,
						values: defaultValues.map((value) => ({ name: value })),
					};
				}

				initKeywordOptions();

				$scope.$watch("model.value", (modelValue) => {
					$scope.field.values = modelValue;
				});
			},
			scope: {
				field: "=",
				model: "=",
				collaborate: "<",
			},
			templateUrl: Luma.paths.context + "/system/mantle/components/canopyCore/directives/form-field/canopy-form-field-keywords.template.html"
		};
	});
})(canopyCore);
