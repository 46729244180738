(function (canopyCore, angular) {
	"use strict";

	canopyCore.filter("canopySanitize", function () {
		return function (inputString) {
			if (!inputString) return inputString;
			// Regular expression to match substrings starting with <a and ending with </a>
			const regex = /<a[\s\S]*?<\/a>/gi;
			// Use match method to find all matches
			const matches = inputString.match(regex);

			if (matches) {
				matches.forEach(match => {
					// Check if href contains "javascript:"
					if (/href\s*=\s*["']javascript:/.test(match)) {
						// Replace href attribute with an empty string
						const sanitizedMatch = match.replace(/href\s*=\s*["']javascript:[^"']*["']/, "href=\"\"");
						// Replace the original match with the sanitized version in the input string
						inputString = inputString.replace(match, sanitizedMatch);
					}
				});
			}

			return inputString;
		};
	});

})(canopyCore, angular);